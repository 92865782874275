<template>
  <div>
    <section id="login">
      <v-container class="login_container">
        <v-row class="d-flex flex-column justify-center align-center">
          <img
            class="pa-10"
            :src="require(`@/assets/logo.svg`)"
            alt="app logo"
          />
          <s-card class="login-card col-12" outlined>
            <h3 class="login-card__header align-center text-center">Sign in</h3>
            <s-btn
              testButton="submit-button"
              type="submit"
              color="primary"
              class="ma-0"
              elevation="0"
              block
              @click="startGoogleSignin"
            >
              Sign In With Google
            </s-btn>
          </s-card>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Login',
  methods: {
    startGoogleSignin () {
      const url = `${process.env.VUE_APP_API_DOMAIN}/v1/auth`
      window.open(url, '_self')
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
@import "@/assets/styles/_mixins.scss";
#login {
  @include center-children;

  & .login_container {
    @include auth-card-dimension;
  }
}

#logo {
  @include auth-logo;
}

.login-card {
  @include auth-card;
}

.signup-link {
  margin-top: 18px;
}

.forgotpass-link {
  @include black-link;
}
.login-card__header {
  @include auth-header;
}
</style>
